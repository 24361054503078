import { Box, Button, Container, Link, Paper, Stack, Typography } from '@mui/material'
import { PageFooter } from 'common/components/Page/Footer'
import MuiTypography from 'common/components/Typography/MuiTypography'
import { useRudderStack } from 'common/hooks/useRudderstack'
import { useEffect } from 'react'

export default function Custom404 () {
  const { pageEvent } = useRudderStack()

  useEffect(() => {
    pageEvent('404 URL')
  }, [])

  return (
    <Stack
      id='error-layout-page'
      sx={{
        position: 'absolute',
        inset: 0
      }}
    >
      <Container sx={{
        px: { xs: 1.5 },
        py: 2
      }}
      >
        <Paper
          variant='outlined'
          sx={{
            maxWidth: 808,
            py: 5,
            px: 3,
            mx: 'auto',
            mt: { xs: '0', sm: 10 }
          }}
        >
          <Stack
            direction='row'
          >
            <Stack
              flex={1}
              alignItems={{ xs: 'center', sm: 'flex-start' }}
              spacing={3}
            >
              <img
                height={27}
                src='/assets/ohmyhome.svg'
                alt='logo'
              />
              <Box
                component='img'
                src='/assets/not-found.svg'
                alt='not-found'
                height={200}
                sx={{
                  display: { xs: 'block', sm: 'none' }
                }}
              />
              <Typography fontSize={48}>Oops!</Typography>
              <MuiTypography
                variantStyle='Body2'
                text='Sorry, we can’t find the page you’re looking for.'
                light
                textAlign={{ xs: 'center', sm: 'left' }}
              />
              <Link
                href='/household/'
                underline='none'
              >
                <Button variant='contained'> Go to Household Page</Button>
              </Link>
            </Stack>
            <Stack
              flex={1}
              alignItems='flex-end'
              sx={{
                p: 3,
                display: { xs: 'none', sm: 'flex' }
              }}
            >
              <img
                src='/assets/not-found.svg'
                alt='not-found'
                height={200}
              />
            </Stack>
          </Stack>
        </Paper>
        <Box sx={{
          position: { xs: 'relative', sm: 'absolute' },
          bottom: 0,
          left: 0,
          right: 0
        }}
        >
          <PageFooter />

        </Box>
      </Container>
    </Stack>
  )
}
